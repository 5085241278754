// LayersMenus.jsx
import React from "react";

const LayersMenus = ({ onDelete, onDuplicate }) => {
    return (
        <div className="pos absolute bg-white border border-gray-300 shadow-lg rounded p-2">
            <ul className="list-none">
                <li className="cursor-pointer font-mono py-1 text-[13px] hover:text-[#ff9354]" onClick={onDelete}>Delete</li>
                <li className="cursor-pointer font-mono py-1 text-[13px] hover:text-[#ff9354]" onClick={onDuplicate}>Duplicate</li>
            </ul>
        </div>
    );
};

export default LayersMenus;
