import React from 'react';

const SelectionIcon = () => {

    return (
        <div className='flex items-center text-sm p-3'>
            <p className='mr-4 font-medium'>Selection Tool</p>
        </div>
    );
};

export default SelectionIcon;
