import React, { useState } from "react";
import "./style.css";
import Logo from "../../../images/vectora-logo.png";
import { Link, useNavigate } from "react-router-dom";
import { FaFacebookF, FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import { FcGoogle } from "react-icons/fc";
import Girl from "../../../images/girl-img.png";
import axiosInstance from "../../../api";
import { toast } from "react-toastify";
import useUserStore from "../../../store/userStore";
import { useUtilsStore } from "../../../store";

const Signin = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [errors, setErrors] = useState({});
  const setUser = useUserStore((state) => state.setUser);
  const navigate = useNavigate();
  const { startLoading, stopLoading } = useUtilsStore((state) => state);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const validateForm = () => {
    const errors = {};
    if (!email) {
      errors.email = "Email is required";
    }
    if (!password) {
      errors.password = "Password is required";
    }
    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleLogin = async (event) => {
    event.preventDefault();
    if (!validateForm()) {
      return;
    }
    try {
      startLoading();
      const response = await axiosInstance.post("/auth/signin", {
        email,
        password,
      });
      const data = response.data;
      if (data.token) {
        setUser(data.user);
        localStorage.setItem("token", data.token);
        toast.success(`Sign in successful! Welcome, ${data.user.name}`);
        navigate("/");
      }
    } catch (error) {
      toast.error(`Sign in failed: ${error.response.data.error}`);
    }
    stopLoading();
  };

  const handleInputChange = (e, setState) => {
    setState(e.target.value);
    setErrors((prevErrors) => ({ ...prevErrors, [e.target.name]: "" }));
  };

  return (
    <>
      <div className="form-grid">
        <div className="left-form flex flex-col justify-between">
          <div>
            <img src={Logo} alt="" className="" />
          </div>
          <div className="signin-form">
            <h3>Sign in</h3>
            <form className="flex flex-col" onSubmit={handleLogin}>
              <div className="">
                <input
                  type="email"
                  name="email"
                  placeholder="Enter email"
                  className="border border-gray-300 rounded-md px-3 py-2 w-full"
                  value={email}
                  onChange={(e) => handleInputChange(e, setEmail)}
                />
                {errors.email && (
                  <p className="text-red-500 text-sm mt-1">{errors.email}</p>
                )}
              </div>
              <div className="relative">
                <input
                  type={showPassword ? "text" : "password"}
                  name="password"
                  placeholder="Password"
                  className="border border-gray-300 rounded-md px-3 py-2 w-full"
                  value={password}
                  onChange={(e) => handleInputChange(e, setPassword)}
                />
                <span
                  className="absolute top-1/2 transform -translate-y-1/2 right-3 cursor-pointer"
                  onClick={togglePasswordVisibility}
                >
                  {showPassword ? (
                    <FaRegEye className="text-gray-400" />
                  ) : (
                    <FaRegEyeSlash className="text-gray-400" />
                  )}
                </span>
                {errors.password && (
                  <p className="text-red-500 text-sm mt-1">{errors.password}</p>
                )}
              </div>
              <Link to="" className="text-end forgot-password">
                Forgot password?
              </Link>
              <button
                type="submit"
                className="signin-btn bg-blue-500 text-white rounded-md px-4 py-2"
              >
                Sign in
              </button>
              <p className="text-center not-account">
                Don’t have an account? <Link to="/signup">Signup</Link>
              </p>
              <p className="text-center or-text">
                <span>or sign in with</span>
              </p>
              <button type="button" className="signin-other border">
                <FcGoogle className="" />
                Google
              </button>
              <button type="button" className="signin-other border">
                <FaFacebookF className="text-[#3A589B]" />
                Facebook
              </button>
            </form>
          </div>
          <div>
            <p className="mb-0">
              <span>© 2024 Vectora. All Rights Reserved</span>
            </p>
          </div>
        </div>
        <div className="right-image">
          <div className="glass-card">
            <h2>
              Experience Innovation, Sign in and Paint Your Digital Canvas with
              Vectora.
            </h2>
          </div>
          <div className="girl-img">
            <img src={Girl} alt="" className="" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Signin;
