import React, { createContext, useContext, useState } from "react";

const LayerContext = createContext();

export const LayerProvider = ({ children }) => {
    const [layers, setLayers] = useState([]);
    const [rectangles, setRectangles] = useState([]);
    const [selectedRectangleIndex, setSelectedRectangleIndex] = useState(null);
    const [canvasDimensions, setCanvasDimensions] = useState({ width: 0, height: 0 });

    const toggleLayerVisibility = (index) => {
        setLayers((prevLayers) =>
            prevLayers.map((layer, i) =>
                i === index ? { ...layer, visible: !layer.visible } : layer
            )
        );
    };

    const deleteLayer = (index) => {
        setLayers((prevLayers) => prevLayers.filter((_, i) => i !== index));
        setRectangles((prevRectangles) => prevRectangles.filter((_, i) => i !== index));
    };

    return (
        <LayerContext.Provider
            value={{
                layers,
                setLayers,
                toggleLayerVisibility,
                deleteLayer,
                rectangles,
                setRectangles,
                selectedRectangleIndex,
                setSelectedRectangleIndex,
                canvasDimensions,
                setCanvasDimensions,
            }}
        >
            {children}
        </LayerContext.Provider>
    );
};

export const useLayerContext = () => useContext(LayerContext);
