import { useEffect, useState } from 'react';
import { useLayerContext } from '../../../context/LayerContext';

export const useRectangleTool = (canvasRef, selectedTool) => {
    const [rect, setRect] = useState(null);
    const [drawing, setDrawing] = useState(false);
    const [moving, setMoving] = useState(false);
    const [resizeHandle, setResizeHandle] = useState(null);
    const { layers, setLayers, rectangles, setRectangles } = useLayerContext();
    const [fillColor, setFillColor] = useState('black');
    const [selectedRectangleIndex, setSelectedRectangleIndex] = useState(null);

    useEffect(() => {
        const canvas = canvasRef.current;

        const handleMouseDown = (event) => {
            const rect = canvas.getBoundingClientRect();
            const mouseX = event.clientX - rect.left;
            const mouseY = event.clientY - rect.top;

            if (selectedTool === 6) {  // Rectangle drawing tool
                setDrawing(true);
                setRect({
                    startX: mouseX,
                    startY: mouseY,
                    endX: mouseX,
                    endY: mouseY,
                });
            } else if (selectedTool === 0) {  // Move tool
                const selectedRectIndex = rectangles.findIndex(rect =>
                    mouseX >= rect.startX && mouseX <= rect.endX &&
                    mouseY >= rect.startY && mouseY <= rect.endY
                );
                if (selectedRectIndex !== -1) {
                    setMoving(true);
                    setSelectedRectangleIndex(selectedRectIndex);
                    setRectangles((prevRectangles) =>
                        prevRectangles.map((rect, index) =>
                            index === selectedRectIndex ? { ...rect, offsetX: mouseX - rect.startX, offsetY: mouseY - rect.startY } : rect
                        )
                    );
                }
            }
        };

        const handleMouseMove = (event) => {
            if (drawing && selectedTool === 6) {
                const rect = canvas.getBoundingClientRect();
                setRect(prevRect => ({
                    ...prevRect,
                    endX: event.clientX - rect.left,
                    endY: event.clientY - rect.top,
                }));
            } else if (moving && selectedTool === 0 && selectedRectangleIndex !== null) {
                const rect = canvas.getBoundingClientRect();
                const mouseX = event.clientX - rect.left;
                const mouseY = event.clientY - rect.top;

                setRectangles((prevRectangles) =>
                    prevRectangles?.map((rect, index) =>
                        index === selectedRectangleIndex ? {
                            ...rect,
                            startX: mouseX - rect.offsetX,
                            startY: mouseY - rect.offsetY,
                            endX: mouseX - rect.offsetX + (rect.endX - rect.startX),
                            endY: mouseY - rect.offsetY + (rect.endY - rect.startY)
                        } : rect
                    )
                );
            }
        };

        const handleMouseUp = () => {
            if (drawing && selectedTool === 6) {
                setDrawing(false);
                const newRect = { ...rect, fillColor };
                setRectangles(prevRectangles => [
                    ...(prevRectangles || []),
                    newRect
                ]);
                setRect(null);
                setLayers(prevLayers => [
                    ...(prevLayers || []),
                    { toolName: `Rectangle ${prevLayers ? prevLayers.length + 1 : 1}`, visible: true }
                ]);
            } else if (moving && selectedTool === 0) {
                setMoving(false);
                // setSelectedRectangleIndex(null);
            }
        };
        
        canvas.addEventListener('mousedown', handleMouseDown);
        canvas.addEventListener('mousemove', handleMouseMove);
        canvas.addEventListener('mouseup', handleMouseUp);

        return () => {
            canvas.removeEventListener('mousedown', handleMouseDown);
            canvas.removeEventListener('mousemove', handleMouseMove);
            canvas.removeEventListener('mouseup', handleMouseUp);
        };
    }, [canvasRef, drawing, moving, rect, selectedTool, fillColor, setLayers, setRectangles, selectedRectangleIndex]);

    useEffect(() => {
        if (selectedTool !== 0) {
            setSelectedRectangleIndex(null);
        }
    }, [selectedTool]);

    const handleResizeMouseDown = (event, x, y) => {
        const handle = getHandleAtPoint(x, y, rectangles[selectedRectangleIndex]);
        if (handle) {
            setResizeHandle(handle);
        }
    };

    const handleResizeMouseMove = (event, x, y) => {
        if (resizeHandle && selectedRectangleIndex !== null) {
            const rect = rectangles[selectedRectangleIndex];
            const updatedRect = { ...rect };

            switch (resizeHandle) {
                case 'top-left':
                    updatedRect.startX = x;
                    updatedRect.startY = y;
                    break;
                case 'top-right':
                    updatedRect.endX = x;
                    updatedRect.startY = y;
                    break;
                case 'bottom-left':
                    updatedRect.startX = x;
                    updatedRect.endY = y;
                    break;
                case 'bottom-right':
                    updatedRect.endX = x;
                    updatedRect.endY = y;
                    break;
                case 'top-center':
                    updatedRect.startY = y;
                    break;
                case 'bottom-center':
                    updatedRect.endY = y;
                    break;
                case 'right-center':
                    updatedRect.endX = x;
                    break;
                case 'left-center':
                    updatedRect.startX = x;
                    break;
                default:
                    break;
            }

            setRectangles(prevRectangles => prevRectangles.map((r, i) => i === selectedRectangleIndex ? updatedRect : r));
        }
    };

    const handleResizeMouseUp = () => {
        setResizeHandle(null);
    };

    const getHandleAtPoint = (x, y, rect) => {
        const handles = ['top-left', 'top-right', 'bottom-left', 'bottom-right', 'top-center', 'bottom-center', 'right-center', 'left-center'];
        const tolerance = 10;

        for (let handle of handles) {
            let handleX, handleY;

            switch (handle) {
                case 'top-left':
                    handleX = rect.startX;
                    handleY = rect.startY;
                    break;
                case 'top-right':
                    handleX = rect.endX;
                    handleY = rect.startY;
                    break;
                case 'bottom-left':
                    handleX = rect.startX;
                    handleY = rect.endY;
                    break;
                case 'bottom-right':
                    handleX = rect.endX;
                    handleY = rect.endY;
                    break;
                case 'top-center':
                    handleX = (rect.startX + rect.endX) / 2;
                    handleY = rect.startY;
                    break;
                case 'bottom-center':
                    handleX = (rect.startX + rect.endX) / 2;
                    handleY = rect.endY;
                    break;
                case 'right-center':
                    handleX = rect.endX;
                    handleY = (rect.startY + rect.endY) / 2;
                    break;
                case 'left-center':
                    handleX = rect.startX;
                    handleY = (rect.startY + rect.endY) / 2;
                    break;
                default:
                    break;
            }

            if (Math.abs(x - handleX) < tolerance && Math.abs(y - handleY) < tolerance) {
                return handle;
            }
        }

        return null;
    };

    return { rect, rectangles, setFillColor, setSelectedRectangleIndex, selectedRectangleIndex, resizeHandle, setResizeHandle, handleResizeMouseDown, handleResizeMouseMove, handleResizeMouseUp };
};

export const drawRectangle = (ctx, rect, fillColor) => {
    const { startX, startY, endX, endY } = rect;
    const width = (endX - startX);
    const height = (endY - startY);
    ctx.save();
    ctx.fillStyle = fillColor;
    ctx.fillRect(startX, startY, width, height);
    ctx.restore();
};

export const drawHandles = (ctx, rect) => {
    const handleRadius = 5;
    const { startX, startY, endX, endY } = rect;
    const handles = [
        { x: startX, y: startY }, // top-left
        { x: endX, y: startY }, // top-right
        { x: startX, y: endY }, // bottom-left
        { x: endX, y: endY }, // bottom-right
        { x: (startX + endX) / 2, y: startY }, // top-center
        { x: (startX + endX) / 2, y: endY }, // bottom-center
        { x: endX, y: (startY + endY) / 2 }, // right-center
        { x: startX, y: (startY + endY) / 2 } // left-center
    ];

    ctx.save();
    ctx.strokeStyle = '#FF9354';
    ctx.lineWidth = 2;

    ctx.strokeRect(startX, startY, endX - startX, endY - startY);

    ctx.fillStyle = 'white';
    ctx.lineWidth = 2;

    handles.forEach(handle => {
        ctx.beginPath();
        ctx.arc(handle.x, handle.y, handleRadius, 0, 2 * Math.PI);
        ctx.fill();
        ctx.stroke();
    });

    ctx.restore();
};
