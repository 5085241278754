// Iconbar.jsx
import React, { useState, useEffect } from "react";
import SelectionIcon from "../../images/selection.png";
import DirectSelectionIcon from "../../images/directselection.png";
import MagicIcon from "../../images/magicwand.png";
import PenIcon from "../../images/pen.png";
import TextIcon from "../../images/text.png";
import LineIcon from "../../images/line.png";
import RectangleIcon from "../../images/square.png";
import PencilIcon from "../../images/pencil.png";
import RotateIcon from "../../images/rotate.png";
import ScaleIcon from "../../images/scale.png";
import GradientIcon from "../../images/gradient.png";
import ArtboardIcon from "../../images/artboard.png";
import ZoomIcon from "../../images/zoom.png";
import { TfiArrowsVertical } from "react-icons/tfi";
import "./style.css";

const Iconbar = ({ handleToolSelect }) => {
    const [selectedIcon, setSelectedIcon] = useState(0);

    const shortcuts = {
        v: 0, // Selection
        m: 1, // Direct Selection
        w: 2, // Magic Wand
        p: 3, // Pen
        t: 4, // Text
        l: 5, // Line
        r: 6, // Rectangle
        c: 7, // Pencil
        o: 8, // Rotate
        s: 9, // Scale
        g: 10, // Gradient
        a: 11, // Artboard
        z: 12, // Zoom
    };

    useEffect(() => {
        const handleKeyPress = (event) => {
            if (!event.ctrlKey && !event.altKey && !event.shiftKey && !event.metaKey) {
                const key = event.key.toLowerCase();
                if (shortcuts.hasOwnProperty(key)) {
                    const toolIndex = shortcuts[key];
                    setSelectedIcon(toolIndex);
                    handleToolSelect(toolIndex);
                }
            }
        };

        window.addEventListener("keydown", handleKeyPress);

        return () => {
            window.removeEventListener("keydown", handleKeyPress);
        };
    }, [handleToolSelect, shortcuts]);

    const icons = [
        { src: SelectionIcon, alt: "Selection", toolIndex: 0 },
        { src: DirectSelectionIcon, alt: "Direct Selection", toolIndex: 1 },
        { src: MagicIcon, alt: "Magic Wand", toolIndex: 2 },
        { src: PenIcon, alt: "Pen", toolIndex: 3 },
        { src: TextIcon, alt: "Text", toolIndex: 4 },
        { src: LineIcon, alt: "Line", toolIndex: 5 },
        { src: RectangleIcon, alt: "Rectangle", toolIndex: 6 },
        { src: PencilIcon, alt: "Pencil", toolIndex: 7 },
        { src: RotateIcon, alt: "Rotate", toolIndex: 8 },
        { src: ScaleIcon, alt: "Scale", toolIndex: 9 },
        { src: GradientIcon, alt: "Gradient", className: "gradient-icon", toolIndex: 10 },
        { src: ArtboardIcon, alt: "Artboard", toolIndex: 11 },
        { src: ZoomIcon, alt: "Zoom", toolIndex: 12 },
    ];

    return (
        <div className="iconbar border-r-[1px] border-[#dddddd] inline-block h-full">
            {icons.map((icon, index) => (
                <div
                    key={index}
                    className={`icon-box ${selectedIcon === icon.toolIndex ? "active-icon-box" : ""}`}
                    onClick={() => {
                        setSelectedIcon(icon.toolIndex);
                        handleToolSelect(icon.toolIndex);
                    }}
                >
                    <img
                        src={icon.src}
                        alt={icon.alt}
                        className={icon.className || ""}
                    />
                </div>
            ))}
            <div className="background-forground flex flex-col gap-2 items-center mt-[15px]">
                <div>
                    <span className="background w-[22px] h-[22px] block bg-[#3A3A3A]"></span>
                </div>
                <div>
                    <TfiArrowsVertical />
                </div>
                <div>
                    <span className="forground w-[22px] h-[22px] block bg-white border-[#808184] border-[5px]"></span>
                </div>
            </div>
        </div>
    );
};

export default Iconbar;
