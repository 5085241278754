import React from "react";
import Spinner from "./Spinner";
const Loader = ({ loading }) => {
  return (
    <>
      {loading && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-500 bg-opacity-50 backdrop-blur-lg">
          <div className="flex justify-center mb-4">
            <Spinner color="#FF9354" />
          </div>
        </div>
      )}
    </>
  );
};

export default Loader;
