import React, { useState } from "react";
import "./style.css";
import Logo from "../../images/vectora-logo.png";
import { Link } from "react-router-dom";
import Homeicon from "../../images/home-grey.svg";
import Bookicon from "../../images/book-grey.svg";
import Fileicon from "../../images/file-grey.svg";
import Usersicon from "../../images/users-grey.svg";
import { GiUpgrade } from "react-icons/gi";
import { HiMiniQuestionMarkCircle } from "react-icons/hi2";
import { useUserStore } from "../../store";
import UserPopover from "../../component/shared/UserPopover";
// Pages
import OpenFile from "./OpenFile";
import CreateNew from "./CreateNew";
const Home = () => {
  const [tab, setTab] = useState(1);
  const user = useUserStore((state) => state.user);
  return (
    <>
      <div className="h-screen">
        <div className="flex justify-between items-center bg-[#FBFBFB] py-3 px-[17px] border-b-[1px] border-[#d0d0d0]">
          <img src={Logo} alt="" className="w-[144px]" />
          <div className="flex gap-[15px]">
            {!user?.isPremium && (
              <button className="rounded-md text-[#FF9354] bg-white border border-[#FF9354] px-[12px] py-[5px] text-sm font-medium	">
                Upgrade to pro
              </button>
            )}
            {user ? (
              <UserPopover />
            ) : (
              <Link
                to="/signin"
                className="rounded-md bg-[#FF9354] text-white px-[12px] py-[5px] text-sm font-medium	"
              >
                Sign in
              </Link>
            )}
          </div>
        </div>
        <div className="flex main-tab-box">
          <div className="max-w-[210px] min-w-[210px] w-full bg-white border-r-[1px] border-[#d0d0d0] px-2.5 py-[15px] flex flex-col justify-between">
            <div className="flex flex-col gap-[5px] justify-start tab-box">
              <Link
                className={`px-4 py-2 text-sm text-[#505050] font-medium hover:bg-[#FF9354] hover:text-[#fff] rounded-[5px] flex gap-[8px] items-center ${tab === 1 ? "text-[#fff] bg-[#FF9354] tab-active" : ""
                  }`}
                to=""
                onClick={() => setTab(1)}
              >
                <img src={Homeicon} className="img-icon" alt="" />
                Home
              </Link>
              <Link
                className={`px-4 py-2 text-sm text-[#505050] font-medium hover:bg-[#FF9354] hover:text-[#fff] rounded-[5px] flex gap-[8px] items-center ${tab === 2 ? "text-[#fff] bg-[#FF9354] tab-active" : ""
                  }`}
                to=""
                onClick={() => setTab(2)}
              >
                <img src={Fileicon} className="img-icon" alt="" />
                Open File
              </Link>
              <Link
                className={`px-4 py-2 text-sm text-[#505050] font-medium hover:bg-[#FF9354] hover:text-[#fff] rounded-[5px] flex gap-[8px] items-center ${tab === 3 ? "text-[#FFF] bg-[#FF9354] tab-active" : ""
                  }`}
                to=""
                onClick={() => setTab(3)}
              >
                <img src={Bookicon} className="img-icon" alt="" />
                Tutorial
              </Link>
              <Link
                className={`px-4 py-2 text-sm text-[#505050] font-medium hover:bg-[#FF9354] hover:text-[#fff] rounded-[5px] flex gap-[8px] items-center ${tab === 4 ? "text-[#fff] bg-[#FF9354] tab-active" : ""
                  }`}
                to=""
                onClick={() => setTab(4)}
              >
                <img src={Usersicon} className="img-icon" alt="" />
                Community
              </Link>
            </div>
            <div className="flex flex-col gap-[5px] justify-start">
              {!user?.isPremium && (
                <Link
                  className={`px-4 py-2 text-sm text-[#505050] font-medium bg-white rounded-[5px] flex gap-[8px] items-center hover:text-[#ff9354]`}
                  to=""
                >
                  <GiUpgrade className="text-lg" />
                  Upgrade to pro
                </Link>
              )}
              <Link
                className={`px-4 py-2 text-sm text-[#505050] font-medium bg-white rounded-[5px] flex gap-[8px] items-center hover:text-[#ff9354]`}
                to=""
              >
                <HiMiniQuestionMarkCircle className="text-lg" />
                Help Center
              </Link>
            </div>
          </div>
          <div className="bg-[#F3F3F3] px-14 pt-10 pb-10 w-full overflow-y-auto">
            <div className="">
              <div
                className="space-y-6"
                style={{ display: tab === 1 ? "block" : "none" }}
              >
                <CreateNew />
              </div>
              <div
                className="space-y-6"
                style={{ display: tab === 2 ? "block" : "none" }}
              >
                <OpenFile />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
