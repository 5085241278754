import React, { useEffect, useContext, useRef } from 'react';
import { ZoomContext } from '../../../context/ZoomContext';
import { useRectangleTool, drawRectangle, drawHandles } from './RectangleTool';
import { useZoomTool } from './ZoomTool';
import { useLayerContext } from '../../../context/LayerContext';

const Canvas = ({ width, height, selectedTool }) => {
    const { state, dispatch } = useContext(ZoomContext);
    const { layers, setSelectedRectangleIndex, rectangles, setCanvasDimensions } = useLayerContext();

    const canvasRef = useRef(null);

    const { rect, selectedRectangleIndex, resizeHandle, handleResizeMouseDown, handleResizeMouseMove, handleResizeMouseUp } = useRectangleTool(canvasRef, selectedTool);
    useZoomTool(canvasRef, state, dispatch);

    useEffect(() => {
        const canvas = canvasRef.current;
        const context = canvas.getContext('2d');
        setupCanvas(canvas);
        setCanvasDimensions({ width, height });

        const handleResize = () => {
            setCanvasDimensions({ width: canvas.width, height: canvas.height });
            drawCanvas(context);
        };
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [width, height]);

    useEffect(() => {
        const context = canvasRef.current.getContext('2d');
        drawCanvas(context);
    }, [state.scale, rect, rectangles, layers, selectedRectangleIndex]);

    const setupCanvas = (canvas) => {
        canvas.width = width;
        canvas.height = height;
        canvas.style.display = 'block';
        canvas.style.margin = 'auto';
        canvas.style.border = '1px solid #000';
    };

    const drawCanvas = (ctx) => {
        ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
        ctx.save();
        ctx.scale(state.scale, state.scale);
        ctx.fillStyle = "white";
        ctx.fillRect(0, 0, ctx.canvas.width, ctx.canvas.height);
        ctx.restore();

        for (let i = rectangles?.length - 1; i >= 0; i--) {
            const rect = rectangles[i];
            const layer = layers[i];
            if (layer && layer.visible) {
                drawScaledRectangle(ctx, rect, rect.fillColor, i === selectedRectangleIndex);
            }
        }

        if (rect) {
            drawScaledRectangle(ctx, rect, 'black', true);
        }
    };

    const drawScaledRectangle = (ctx, rect, fillColor, isSelected) => {
        const scaledRect = {
            startX: rect.startX * state.scale,
            startY: rect.startY * state.scale,
            endX: rect.endX * state.scale,
            endY: rect.endY * state.scale,
        };
        drawRectangle(ctx, scaledRect, fillColor);
        if (isSelected && selectedTool === 0) {
            drawHandles(ctx, scaledRect);
        }
    };

    const getCanvasCoordinates = (event) => {
        const rect = canvasRef.current.getBoundingClientRect();
        const x = event.clientX - rect.left;
        const y = event.clientY - rect.top;
        return { x, y };
    };

    const handleMouseDownCanvas = (event) => {
        const { x, y } = getCanvasCoordinates(event);

        if (selectedTool === 0 && selectedRectangleIndex !== null) {
            handleResizeMouseDown(event, x, y);
        } else {
            rectangles?.forEach((rectangle, index) => {
                if (
                    x >= rectangle.startX &&
                    x <= rectangle.endX &&
                    y >= rectangle.startY &&
                    y <= rectangle.endY
                ) {
                    setSelectedRectangleIndex(index);
                }
            });
        }
    };

    const handleMouseMoveCanvas = (event) => {
        const { x, y } = getCanvasCoordinates(event);

        if (resizeHandle) {
            handleResizeMouseMove(event, x, y);
        }
    };

    const handleMouseUpCanvas = (event) => {
        handleResizeMouseUp();
    };

    return (
        <canvas
            ref={canvasRef}
            width={width}
            height={height}
            onMouseDown={handleMouseDownCanvas}
            onMouseMove={handleMouseMoveCanvas}
            onMouseUp={handleMouseUpCanvas}
            style={{ display: 'block', margin: 'auto', border: '1px solid black' }}
        />
    );
};

export default Canvas;
