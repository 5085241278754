import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useUserStore } from "../../store";
import noUser from "../../images/noUser.png";

const UserPopover = () => {
  const user = useUserStore((state) => state.user);
  const clearUser = useUserStore((state) => state.clearUser);
  const [isOpen, setIsOpen] = useState(false);
  const popoverRef = useRef();
  const navigate = useNavigate();

  const handleSignOut = () => {
    clearUser();
    localStorage.removeItem("token");
    navigate("/signin");
  };

  const handleClickOutside = (event) => {
    if (popoverRef.current && !popoverRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="relative">
      <button onClick={() => setIsOpen(!isOpen)} className="focus:outline-none">
        <img
          src={user?.avatar || noUser}
          alt="User Avatar"
          className="w-10 h-10 rounded-full border-2 border-primary"
        />
      </button>
      {isOpen && (
        <div
          ref={popoverRef}
          className="absolute right-0 mt-2 w-48 bg-white border border-gray-300 rounded-lg shadow-lg z-10"
        >
          <div className="p-4 flex flex-col items-center">
            <img
              src={user?.avatar || noUser}
              alt="User Avatar"
              className="w-20 h-20 border-2 border-primary rounded-full mb-2"
            />
            <p className="text-gray-800 font-semibold">Hi {user?.name}!</p>
            <p className="text-gray-600 text-sm mb-2">{user?.email}</p>
            <button
              onClick={handleSignOut}
              className="rounded-md bg-[#FF9354] text-white px-[12px] py-[5px] text-sm font-medium	"
            >
              Sign Out
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default UserPopover;
