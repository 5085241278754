import React, { useRef } from 'react';
import { FaEye } from 'react-icons/fa';
import { AiFillSetting } from 'react-icons/ai';
import { useDrag, useDrop } from 'react-dnd';
import './Layers.css';

const LayerItem = ({
  layer,
  index,
  moveLayer,
  handleLayerClick,
  isSelected,
  handleSettingsClick,
  handleColorBoxClick,
  toggleLayerVisibility,
  rectangles
}) => {
  const ref = useRef(null);

  const [{ isDragging }, drag] = useDrag({
    type: 'layer',
    item: { index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [, drop] = useDrop({
    accept: 'layer',
    hover(item) {
      if (item.index !== index) {
        moveLayer(item.index, index);
        item.index = index;
      }
    },
  });

  drag(drop(ref));

  return (
    <div
      ref={ref}
      className={`flex items-center p-1 mb-1 relative ${isSelected ? 'bg-[#bababa]' : 'bg-[#eee]'
        } ${isDragging ? 'opacity-50' : ''}`}
      onClick={() => handleLayerClick(index)}
    >
      <FaEye
        className={`mr-3 cursor-pointer ${layer.visible ? 'text-black' : 'text-gray-500'
          }`}
        onClick={(e) => {
          e.stopPropagation();
          toggleLayerVisibility(index);
        }}
      />
      <span
        className="w-[25px] h-[25px] border border-black mr-2 cursor-pointer"
        style={{ backgroundColor: rectangles[index]?.fillColor || '#000' }}
        onClick={(e) => {
          e.stopPropagation();
          handleColorBoxClick(index);
        }}
      ></span>
      <p className="flex-1 font-mono text-[12px]">
        {layer.toolName || layer.text}
      </p>
      <AiFillSetting
        className="cursor-pointer text-gray-500"
        onClick={(e) => {
          e.stopPropagation();
          handleSettingsClick(index);
        }}
      />
    </div>
  );
};

export default LayerItem;