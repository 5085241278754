import React from "react";

function Spinner({ color }) {
  return (
    <div
      className="w-10 h-10 border-4 border-t-4 border-gray-200 rounded-full animate-spin"
      style={{ borderTopColor: color }}
    ></div>
  );
}

export default Spinner;
