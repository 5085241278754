import React, { useState, useEffect } from 'react';
import { useLayerContext } from '../../context/LayerContext';
import LayersMenus from './LayersMenus';
import ColorPicker from '../ColorPicker';
import LayerItem from './LayerItem';
import './Layers.css';

const Layers = ({ jsonData }) => {
    const {
        layers,
        setLayers,
        toggleLayerVisibility,
        deleteLayer,
        rectangles,
        setRectangles,
        selectedRectangleIndex,
        setSelectedRectangleIndex,
        canvasDimensions,
        setCanvasDimensions
    } = useLayerContext();

    const [menuIndex, setMenuIndex] = useState(null);
    const [color, setColor] = useState('#000');
    const [selectedLayer, setSelectedLayer] = useState(null);
    const [activeColorPickerIndex, setActiveColorPickerIndex] = useState(null);

    useEffect(() => {
        if (jsonData) {
            const { layers, rectangles } = jsonData;
            setLayers(layers);
            setRectangles(rectangles);
        }
    }, [jsonData, setLayers, setRectangles, setCanvasDimensions]);
    useEffect(() => {
        if (layers?.length > 0) {
            setSelectedLayer(layers?.length - 1);
        }
    }, [layers]);

    useEffect(() => {
        if (selectedRectangleIndex !== null) {
            setSelectedLayer(selectedRectangleIndex);
        }
    }, [selectedRectangleIndex]);

    const moveLayer = (fromIndex, toIndex) => {
        const updatedLayers = [...layers];
        const updatedRectangles = [...rectangles];
        const [movedLayer] = updatedLayers.splice(fromIndex, 1);
        const [movedRectangle] = updatedRectangles.splice(fromIndex, 1);

        updatedLayers.splice(toIndex, 0, movedLayer);
        updatedRectangles.splice(toIndex, 0, movedRectangle);

        setLayers(updatedLayers);
        setRectangles(updatedRectangles);
    };

    const handleSettingsClick = (index) => {
        setMenuIndex(menuIndex === index ? null : index);
    };

    const handleColorBoxClick = (index) => {
        setActiveColorPickerIndex(index);
    };

    const handleColorChange = (color, index) => {
        setColor(color);
        if (index !== null && index < rectangles?.length) {
            setRectangles((prevRectangles) =>
                prevRectangles?.map((rect, i) =>
                    i === index ? { ...rect, fillColor: color } : rect
                )
            );
            setLayers((prevLayers) =>
                prevLayers?.map((layer, i) =>
                    i === index ? { ...layer, fillColor: color } : layer
                )
            );
        }
    };


    const handleLayerClick = (index) => {
        setSelectedLayer(index);
        setSelectedRectangleIndex(index);
    };

    const duplicateLayer = (index) => {
        const layerToDuplicate = layers[index];
        const rectangleToDuplicate = rectangles[index];

        setLayers((prevLayers) => [
            ...prevLayers,
            { ...layerToDuplicate, toolName: `${layerToDuplicate.toolName} Copy`, visible: true },
        ]);
        setRectangles((prevRectangles) => [
            ...prevRectangles,
            { ...rectangleToDuplicate },
        ]);

        setMenuIndex(null);
    };

    const downloadJson = () => {
        const jsonContent = {
            layers: layers?.map(layer => ({
                toolName: layer.toolName,
                visible: layer.visible
            })),
            rectangles: rectangles?.map(rectangle => ({
                startX: rectangle.startX,
                startY: rectangle.startY,
                endX: rectangle.endX,
                endY: rectangle.endY,
                fillColor: rectangle.fillColor,
                offsetX: rectangle.offsetX,
                offsetY: rectangle.offsetY
            })),
            canvasDimensions: canvasDimensions
        };

        const blob = new Blob([JSON.stringify(jsonContent)], { type: 'application/json' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'VDF.json';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
    };

    return (
        <div className="max-w-[300px] min-w-[300px] w-full bg-white relative">
            {layers?.map((layer, index) => (
                <LayerItem
                    key={index}
                    layer={layer}
                    index={index}
                    moveLayer={moveLayer}
                    handleLayerClick={handleLayerClick}
                    isSelected={selectedLayer === index}
                    handleSettingsClick={handleSettingsClick}
                    handleColorBoxClick={handleColorBoxClick}
                    isActiveColorPicker={activeColorPickerIndex === index}
                    handleColorChange={handleColorChange}
                    deleteLayer={deleteLayer}
                    duplicateLayer={duplicateLayer}
                    toggleLayerVisibility={toggleLayerVisibility}
                    rectangles={rectangles}
                />
            ))}
            {menuIndex !== null && (
                <LayersMenus
                    onDelete={() => {
                        deleteLayer(menuIndex);
                        setMenuIndex(null);
                    }}
                    onDuplicate={() => duplicateLayer(menuIndex)}
                />
            )}
            {activeColorPickerIndex !== null && (
                <ColorPicker
                    color={layers[activeColorPickerIndex]?.fillColor || '#000'}
                    onChange={(color) => handleColorChange(color.hex, activeColorPickerIndex)}
                    onClose={() => setActiveColorPickerIndex(null)}
                />
            )}
            <button onClick={downloadJson}>Download Json</button>
        </div>
    );
};

export default Layers;
