// TopMenubar.jsx
import React from 'react';
import SelectionIcon from './SelectionIcon';
import DirectSelectionIcon from './DirectSelectionIcon';
import MagicIcon from './MagicIcon';
import PenIcon from './PenIcon';
import TextIcon from './TextIcon';
import LineIcon from './LineIcon';
import RectangleIcon from './RectangleIcon';
import PencilIcon from './PencilIcon';
import RotateIcon from './RotateIcon';
import ScaleIcon from './ScaleIcon';
import GradientIcon from './GradientIcon';
import ArtboardIcon from './ArtboardIcon';
import ZoomIcon from './ZoomIcon';

const TopMenubar = ({ selectedTool }) => {
    return (
        <div className="bg-[#FBFBFB] h-[50px] flex items-center justify-between pl-1.5 pr-6 border-b-[1px] border-[#dddddd]">
            {selectedTool === 0 && <SelectionIcon />}
            {selectedTool === 1 && <DirectSelectionIcon />}
            {selectedTool === 2 && <MagicIcon />}
            {selectedTool === 3 && <PenIcon />}
            {selectedTool === 4 && <TextIcon />}
            {selectedTool === 5 && <LineIcon />}
            {selectedTool === 6 && <RectangleIcon />}
            {selectedTool === 7 && <PencilIcon />}
            {selectedTool === 8 && <RotateIcon />}
            {selectedTool === 9 && <ScaleIcon />}
            {selectedTool === 10 && <GradientIcon />}
            {selectedTool === 11 && <ArtboardIcon />}
            {selectedTool === 12 && <ZoomIcon />}
        </div>
    );
};

export default TopMenubar;