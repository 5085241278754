// utilsStore.js

import create from "zustand";

const useUtilsStore = create((set) => ({
  loading: false,
  startLoading: (user) => set({ loading: true }),
  stopLoading: () => set({ loading: false }),
}));

export default useUtilsStore;
