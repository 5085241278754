import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Topbar from "../../component/Topbar";
import TopMenubar from "../../component/TopMenubar";
import Iconbar from "../../component/Iconbar";
import Layers from "../../component/Layers";
import Canvas from './Canvas';
import Ruler from './Ruler';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { LayerProvider } from "../../context/LayerContext";

const Workspace = () => {
    const location = useLocation();
    const jsonData = location.state;
    const { width, height } = location.state || { width: 0, height: 0 };


    const [selectedTool, setSelectedTool] = useState(0);
    const [guidelines, setGuidelines] = useState([]);
    const [tempGuideline, setTempGuideline] = useState(null);
    const [showRuler, setShowRuler] = useState(true);
    const [showGuidelines, setShowGuidelines] = useState(true);
    const [draggedGuideline, setDraggedGuideline] = useState(null);

    const handleToolSelect = (toolIndex) => {
        setSelectedTool(toolIndex);
    };

    const addTempGuideline = (guideline) => {
        setTempGuideline(guideline);
    };

    const handleMouseMove = (e) => {
        if (tempGuideline) {
            const canvasRect = document.querySelector('.main_wrap').getBoundingClientRect();
            const newPosition = tempGuideline.direction === 'horizontal' ? e.clientY - canvasRect.top : e.clientX - canvasRect.left;
            setTempGuideline({ ...tempGuideline, position: newPosition });
        } else if (draggedGuideline !== null) {
            const canvasRect = document.querySelector('.main_wrap').getBoundingClientRect();
            const newPosition = draggedGuideline.direction === 'horizontal' ? e.clientY - canvasRect.top : e.clientX - canvasRect.left;
            setGuidelines((prevGuidelines) =>
                prevGuidelines.map((guideline, index) =>
                    index === draggedGuideline.index
                        ? { ...guideline, position: newPosition }
                        : guideline
                )
            );
        }
    };

    const handleMouseUp = () => {
        if (tempGuideline) {
            setGuidelines([...guidelines, tempGuideline]);
            setTempGuideline(null);
        }
        setDraggedGuideline(null);
    };

    const startDraggingGuideline = (index) => {
        setDraggedGuideline({ ...guidelines[index], index });
    };

    const deleteGuideline = (index) => {
        setGuidelines((prevGuidelines) => prevGuidelines.filter((_, i) => i !== index));
    };

    const handleDoubleClick = (index) => {
        deleteGuideline(index);
    };

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.ctrlKey) {
                if (event.key === 'r' || event.key === 'R') {
                    event.preventDefault();
                    setShowRuler((prevShowRuler) => !prevShowRuler);
                } else if (event.key === 'h' || event.key === 'H') {
                    event.preventDefault();
                    setShowGuidelines((prevShowGuidelines) => !prevShowGuidelines);
                }
            }
        };

        window.addEventListener('keydown', handleKeyDown);
        window.addEventListener('mousemove', handleMouseMove);
        window.addEventListener('mouseup', handleMouseUp);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
            window.removeEventListener('mousemove', handleMouseMove);
            window.removeEventListener('mouseup', handleMouseUp);
        };
    }, [tempGuideline, draggedGuideline]);

    const getCursorForGuideline = (direction) => {
        switch (direction) {
            case 'horizontal':
                return 'ns-resize';
            case 'vertical':
                return 'ew-resize';
            default:
                return 'grab';
        }
    };

    return (
        <LayerProvider>
            <div className="workspace relative">
                <Topbar />
                <TopMenubar selectedTool={selectedTool} />
                <div className="flex h-full">
                    <Iconbar handleToolSelect={handleToolSelect} />
                    <div className="main_wrap relative bg-[#121212] px-14 pt-10 w-full h-[100vh] overflow-auto">
                        {showRuler && <Ruler addTempGuideline={addTempGuideline} />}
                        <Canvas
                            width={width || jsonData.canvasDimensions.width}
                            height={height || jsonData.canvasDimensions.height}
                            selectedTool={selectedTool}
                        // rectangles={jsonData.rectangles}
                        />
                        {showGuidelines && guidelines.map((guideline, index) => (
                            <div
                                key={index}
                                className="guideline"
                                style={{
                                    position: 'absolute',
                                    left: guideline.direction === 'vertical' ? `${guideline.position}px` : 0,
                                    top: guideline.direction === 'horizontal' ? `${guideline.position}px` : 0,
                                    width: guideline.direction === 'vertical' ? '1px' : '100%',
                                    height: guideline.direction === 'horizontal' ? '1px' : '100%',
                                    backgroundColor: '#3182CE',
                                    cursor: getCursorForGuideline(guideline.direction),
                                    zIndex: 1000,
                                    pointerEvents: 'auto',
                                }}
                                onMouseDown={() => startDraggingGuideline(index)}
                                onDoubleClick={() => handleDoubleClick(index)}
                            >
                                <div
                                    style={{
                                        position: 'absolute',
                                        top: '-3px',
                                        left: '-3px',
                                        right: '-3px',
                                        bottom: '-3px',
                                        opacity: 0,
                                    }}
                                />
                            </div>
                        ))}
                        {tempGuideline && (
                            <div
                                className="guideline"
                                style={{
                                    position: 'absolute',
                                    left: tempGuideline.direction === 'vertical' ? `${tempGuideline.position}px` : 0,
                                    top: tempGuideline.direction === 'horizontal' ? `${tempGuideline.position}px` : 0,
                                    width: tempGuideline.direction === 'vertical' ? '1px' : '100%',
                                    height: tempGuideline.direction === 'horizontal' ? '1px' : '100%',
                                    backgroundColor: '#3182CE',
                                    zIndex: 1100,  // Ensure temp guideline is above rulers
                                }}
                            />
                        )}
                    </div>
                    <DndProvider backend={HTML5Backend}>
                        <Layers jsonData={jsonData} />
                    </DndProvider>
                </div>
            </div>
        </LayerProvider>
    );
};

export default Workspace;
