// ZoomIcon.jsx
import React, { useContext } from 'react';
import { GoZoomIn, GoZoomOut } from "react-icons/go";
import { ZoomContext } from '../../context/ZoomContext';

const ZoomIcon = () => {
  const { state, dispatch } = useContext(ZoomContext);

  const handleZoomInClick = () => {
    dispatch({ type: 'SET_ACTIVE', payload: 'zoomIn' });
  };

  const handleZoomOutClick = () => {
    dispatch({ type: 'SET_ACTIVE', payload: 'zoomOut' });
  };

  return (
    <div className='flex items-center text-sm p-3'>
      <p className='mr-4 font-medium'>Zoom Tool</p>
      <div className='flex'>
        <GoZoomIn
          className={`mr-2 cursor-pointer ${state.activeZoom === 'zoomIn' ? 'text-[#ff9354]' : 'hover:text-[#ff9354]'}`}
          onClick={handleZoomInClick}
        />
        <GoZoomOut
          className={`cursor-pointer ${state.activeZoom === 'zoomOut' ? 'text-[#ff9354]' : 'hover:text-[#ff9354]'}`}
          onClick={handleZoomOutClick}
        />
      </div>
    </div>
  );
};

export default ZoomIcon;
