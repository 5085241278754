export const fileMenuItems = [
  {
    items: [
      { label: "New", to: "" },
      { label: "Open", to: "" },
    ],
  },
  {
    items: [
      { label: "Save", to: "" },
      {
        label: "Export as",
        to: "",
        subMenuItems: [
          { label: "JPEG", to: "" },
          { label: "PNG", to: "" },
        ],
      },
      { label: "Place", to: "" },
    ],
  },
  {
    items: [
      { label: "Color mode", to: "" },
      { label: "Setting", to: "" },
      { label: "Print", to: "" },
    ],
  },
];

export const editMenuItems = [
  {
    items: [
      { label: "Undo", to: "" },
      { label: "Redo", to: "" },
    ],
  },
  {
    items: [
      { label: "Cut", to: "" },
      { label: "Copy", to: "" },
      { label: "Paste", to: "" },
    ],
  },
  {
    items: [
      { label: "Delete", to: "" },
      { label: "Duplicate", to: "" },
    ],
  },
  {
    items: [
      { label: "Select all", to: "" },
      { label: "Deselect", to: "" },
    ],
  },
];

export const objectMenuItems = [
  {
    items: [
      { label: "Group", to: "" },
      { label: "Ungroup", to: "" },
      { label: "Lock", to: "" },
      { label: "Unlock", to: "" },
      { label: "Unlock all", to: "" },
      { label: "Hide", to: "" },
      { label: "Unhide", to: "" },
    ],
  },
  {
    items: [
      { label: "Mask", to: "" },
      { label: "Unmask", to: "" },
    ],
  },
];

export const viewMenuItems = [
  {
    items: [{ label: "Outline", to: "" }],
  },
  {
    items: [
      { label: "Actual Size", to: "" },
      { label: "Fit to Window", to: "" },
      { label: "Zoom in", to: "" },
      { label: "Zoom out", to: "" },
    ],
  },
  {
    items: [
      { label: "Hide Drawing Boards", to: "" },
      { label: "Show/Hide Rulers", to: "" },
      { label: "Show/Hide Guides", to: "" },
    ],
  },
  {
    items: [
      { label: "Lock Guides", to: "" },
      { label: "Delete Guides", to: "" },
      { label: "Show/Hide Grid", to: "" },
    ],
  },
];

export const helpMenuItems = [
  {
    items: [{ label: "About", to: "" }],
  },
  {
    items: [{ label: "Language", to: "" }],
  },
  {
    items: [{ label: "Tutorials", to: "" }],
  },
  {
    items: [{ label: "What's New", to: "" }],
  },
  {
    items: [{ label: "Blog", to: "" }],
  },
  {
    items: [{ label: "Report a Bug", to: "" }],
  },
  {
    items: [{ label: "Account Management", to: "" }],
  },
];
