import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";

// Pages
import Home from "./pages/Home";
import Workspace from "./pages/Workspace";

// Components
import Signup from "./component/auth/Signup";
import Signin from "./component/auth/Signin";
import Loader from "./component/shared/Loader";

// Context Providers
import { ZoomProvider } from './context/ZoomContext';
import { LayerProvider } from "./context/LayerContext";

// Store
import { useUtilsStore } from "./store";

const App = () => {
  const loading = useUtilsStore((state) => state.loading);

  return (
    <LayerProvider>
      <ZoomProvider>
          <BrowserRouter>
            <ToastContainer />
            <Loader loading={loading} />
            <Routes>
              <Route path="/signup" element={<Signup />} />
              <Route path="/signin" element={<Signin />} />
              <Route path="/" element={<Home />} />
              <Route path="/workspace" element={<Workspace />} />
            </Routes>
          </BrowserRouter>
      </ZoomProvider>
    </LayerProvider>
  );
};

export default App;
