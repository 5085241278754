import { useEffect } from 'react';

export const useZoomTool = (canvasRef, state, dispatch) => {
    useEffect(() => {
        const canvas = canvasRef.current;

        const handleCanvasClick = () => {
            if (state.zoomMode) {
                if (state.activeZoom === 'zoomIn') {
                    dispatch({ type: 'ZOOM_IN' });
                } else if (state.activeZoom === 'zoomOut') {
                    dispatch({ type: 'ZOOM_OUT' });
                }
            }
        };

        const handleKeyDown = (event) => {
            if (state.zoomMode && event.ctrlKey) {
                if (event.key === '+') {
                    dispatch({ type: 'ZOOM_IN' });
                } else if (event.key === '-') {
                    dispatch({ type: 'ZOOM_OUT' });
                }
            }
        };

        canvas.addEventListener('click', handleCanvasClick);
        window.addEventListener('keydown', handleKeyDown);

        return () => {
            canvas.removeEventListener('click', handleCanvasClick);
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [canvasRef, state.zoomMode, state.activeZoom, dispatch]);

    return {};
};
