import React from 'react';
import { SketchPicker } from 'react-color';

const ColorPicker = ({ color, onChange, onClose }) => {
    return (
        <div style={{ position: 'absolute', top: 0, zIndex: 2 }}>
            <div
                style={{
                    position: 'fixed',
                    top: 0,
                    right: 0,
                    bottom: 0,
                    left: 0,
                }}
                onClick={onClose}
            />
            <SketchPicker color={color} onChange={onChange} />
        </div>
    );
};

export default ColorPicker;
