import React from "react";
import LogoIcon from "../../images/vectora-icon.png";
import Dropdown from "../Dropdown";
import { Link } from "react-router-dom";
import {
  editMenuItems,
  fileMenuItems,
  helpMenuItems,
  objectMenuItems,
  viewMenuItems,
} from "../constants/topbarContent";

const Topbar = () => {
  return (
    <>
      <div className="bg-[#FBFBFB] h-[45px] flex items-center justify-between pl-1.5 pr-6 ">
        <div className="flex items-center">
          <img src={LogoIcon} alt="Logo" />
          <div className="ps-4 flex space-x-3">
            <Dropdown label="File" menuItems={fileMenuItems} />
            <Dropdown label="Edit" menuItems={editMenuItems} />
            <Dropdown label="Object" menuItems={objectMenuItems} />
            <Dropdown label="View" menuItems={viewMenuItems} />
            <Dropdown label="Help" menuItems={helpMenuItems} />
          </div>
        </div>
        <div className="flex gap-[15px]">
          <button className="rounded-md text-[#FF9354] bg-white border border-[#FF9354] px-[12px] py-[5px] text-sm font-medium	">
            Upgrade to pro
          </button>
          <Link
            to="/signin"
            className="rounded-md bg-[#FF9354] text-white px-[12px] py-[5px] text-sm font-medium	"
          >
            Sign in
          </Link>
        </div>
      </div>
    </>
  );
};

export default Topbar;
