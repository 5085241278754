import React, { useState } from "react";
import "./style.css";
import Logo from "../../../images/vectora-logo.png";
import { Link, useNavigate } from "react-router-dom";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import Girl from "../../../images/girl-img.png";
import axiosInstance from "../../../api";
import useUserStore from "../../../store/userStore";
import { useUtilsStore } from "../../../store";

const Signup = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [name, setName] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errors, setErrors] = useState({});
  const setUser = useUserStore((state) => state.setUser);
  const { startLoading, stopLoading } = useUtilsStore((state) => state);

  const navigate = useNavigate();

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const validateForm = () => {
    const newErrors = {};
    let isValid = true;

    if (!name) {
      newErrors.name = "Name is required";
      isValid = false;
    }

    if (!mobile) {
      newErrors.mobile = "Mobile number is required";
      isValid = false;
    }

    if (!email) {
      newErrors.email = "Email is required";
      isValid = false;
    }

    if (!password) {
      newErrors.password = "Password is required";
      isValid = false;
    }

    if (password !== confirmPassword) {
      newErrors.confirmPassword = "Passwords do not match";
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleSignup = async (event) => {
    event.preventDefault();
    if (!validateForm()) {
      return;
    }
    try {
      startLoading();
      const response = await axiosInstance.post("/auth/signup", {
        name,
        mobileNo: mobile,
        email,
        password,
      });
      const data = response.data;
      if (data.token) {
        setUser(data.user);
        localStorage.setItem("token", data.token);
        navigate("/");
      }
    } catch (error) {
      const errorMessage = error.response
        ? error.response.data.message
        : error.message;
      console.error("Signup failed:", errorMessage);
    }
    stopLoading();
  };

  const handleInputChange = (e, setState) => {
    setState(e.target.value);
    setErrors((prevErrors) => ({ ...prevErrors, [e.target.name]: "" }));
  };

  return (
    <>
      <div className="form-grid">
        <div className="left-form flex flex-col justify-between ">
          <div>
            <img src={Logo} alt="" className="" />
          </div>
          <div className="signin-form">
            <h3>Sign up</h3>
            <form className="flex flex-col" onSubmit={handleSignup}>
              <div className="">
                <input
                  type="text"
                  name="name"
                  placeholder="Name"
                  className="border border-gray-300 rounded-md px-3 py-2 w-full"
                  value={name}
                  onChange={(e) => handleInputChange(e, setName)}
                />
                {errors.name && (
                  <p className="text-red-500 text-sm mt-1">{errors.name}</p>
                )}
              </div>
              <div className="">
                <input
                  type="number"
                  name="mobile"
                  placeholder="Mobile"
                  className="border border-gray-300 rounded-md px-3 py-2 w-full"
                  value={mobile}
                  onChange={(e) => handleInputChange(e, setMobile)}
                />
                {errors.mobile && (
                  <p className="text-red-500 text-sm mt-1">{errors.mobile}</p>
                )}
              </div>
              <div className="">
                <input
                  type="email"
                  name="email"
                  placeholder="Enter email"
                  className="border border-gray-300 rounded-md px-3 py-2 w-full"
                  value={email}
                  onChange={(e) => handleInputChange(e, setEmail)}
                />
                {errors.email && (
                  <p className="text-red-500 text-sm mt-1">{errors.email}</p>
                )}
              </div>
              <div className=" relative">
                <input
                  type={showPassword ? "text" : "password"}
                  name="password"
                  placeholder="Password"
                  className="border border-gray-300 rounded-md px-3 py-2 w-full"
                  value={password}
                  onChange={(e) => handleInputChange(e, setPassword)}
                />
                <span
                  className="absolute top-1/2 transform -translate-y-1/2 right-3 cursor-pointer"
                  onClick={togglePasswordVisibility}
                >
                  {showPassword ? (
                    <FaRegEyeSlash className="text-gray-400" />
                  ) : (
                    <FaRegEye className="text-gray-400" />
                  )}
                </span>
                {errors.password && (
                  <p className="text-red-500 text-sm mt-1">{errors.password}</p>
                )}
              </div>
              <div className=" relative">
                <input
                  type={showConfirmPassword ? "text" : "password"}
                  name="confirmPassword"
                  placeholder="Confirm Password"
                  className="border border-gray-300 rounded-md px-3 py-2 w-full"
                  value={confirmPassword}
                  onChange={(e) => handleInputChange(e, setConfirmPassword)}
                />
                <span
                  className="absolute top-1/2 transform -translate-y-1/2 right-3 cursor-pointer"
                  onClick={toggleConfirmPasswordVisibility}
                >
                  {showConfirmPassword ? (
                    <FaRegEyeSlash className="text-gray-400" />
                  ) : (
                    <FaRegEye className="text-gray-400" />
                  )}
                </span>
                {errors.confirmPassword && (
                  <p className="text-red-500 text-sm mt-1">
                    {errors.confirmPassword}
                  </p>
                )}
              </div>
              <button type="submit" className="signin-btn">
                Sign up
              </button>
              <p className="text-center">
                Already have an account?{" "}
                <Link to="/signin" className="text-blue-500">
                  Sign in
                </Link>
              </p>
            </form>
          </div>
          <div>
            <p className="mb-0">
              <span>© 2024 Vectora. All Rights Reserved</span>
            </p>
          </div>
        </div>
        <div className="right-image">
          <div className="glass-card">
            <h2 className="text-white">
              Elevate Your Experience: Sign Up and Explore Limitless
              Possibilities!
            </h2>
          </div>
          <div className="girl-img">
            <img src={Girl} alt="" className="" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Signup;
