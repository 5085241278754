import React from 'react';

const Ruler = ({ addTempGuideline }) => {
    const handleMouseDown = (e, direction) => {
        e.preventDefault();
        const canvasRect = document.querySelector('.main_wrap').getBoundingClientRect();
        const pos = direction === 'horizontal' ? e.clientY - canvasRect.top : e.clientX - canvasRect.left;
        const oppositeDirection = direction === 'horizontal' ? 'vertical' : 'horizontal';
        addTempGuideline({ direction: oppositeDirection, position: pos });
    };

    const handleMouseUp = (e) => {
        e.preventDefault();
        addTempGuideline(null);
    };

    const renderRulerMarks = (orientation) => {
        const marks = [];
        for (let i = 0; i <= 100; i++) {
            const markValue = i * 10;
            marks.push(
                <div
                    key={i}
                    className={`ruler-mark ${orientation}`}
                    style={{
                        position: 'relative',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        flexDirection: orientation === 'horizontal' ? 'column' : 'row',
                    }}
                >
                    {i % 10 === 0 ? (
                        <div
                            className="ruler-label"
                            style={{
                                color: '#ccc',
                                userSelect: 'none',
                                fontSize: '10px',
                                whiteSpace: 'nowrap',
                                transform: orientation === 'horizontal' ? 'rotate(90deg)' : 'rotate(0deg)',
                                transformOrigin: 'center',
                                marginLeft: orientation === 'horizontal' ? 0 : 'auto',
                            }}
                        >
                            {markValue}
                        </div>
                    ) : (
                        <div
                            style={{
                                width: orientation === 'horizontal' ? (i % 5 === 0 ? '2px' : '1px') : '10px',
                                height: orientation === 'vertical' ? (i % 5 === 0 ? '2px' : '1px') : '10px',
                                backgroundColor: i % 5 === 0 ? '#ccc' : '#999',
                                margin: orientation === 'horizontal' ? '0 5px' : '5px 0',
                            }}
                        />
                    )}
                </div>
            );
        }
        return marks;
    };

    return (
        <div className="absolute top-0 left-0 right-0 bottom-0 pointer-events-none">
            <div
                className="absolute h-8 top-0 left-0 w-full bg-gray-800 border-b border-gray-900 z-20 pointer-events-auto"
                onMouseDown={(e) => handleMouseDown(e, 'horizontal')}
                onMouseUp={handleMouseUp}
            >
                <div className="flex justify-between items-center mx-2 h-full">
                    {renderRulerMarks('horizontal')}
                </div>
            </div>

            <div
                className="absolute top-0 left-0 w-8 h-full bg-gray-800 border-r border-gray-900 z-20 pointer-events-auto"
                onMouseDown={(e) => handleMouseDown(e, 'vertical')}
                onMouseUp={handleMouseUp}
            >
                <div className="flex flex-col justify-between items-center my-2 w-full">
                    {renderRulerMarks('vertical')}
                </div>
            </div>
        </div>
    );
};

export default Ruler;
