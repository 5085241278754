// ZoomContext.jsx
import React, { createContext, useReducer } from 'react';

const ZoomContext = createContext();

const zoomReducer = (state, action) => {
    switch (action.type) {
        case 'ZOOM_IN':
            return { ...state, scale: state.scale + 0.1 };
        case 'ZOOM_OUT':
            return { ...state, scale: state.scale - 0.1 };
        case 'SET_ACTIVE':
            return { ...state, activeZoom: action.payload, zoomMode: true };
        case 'DEACTIVATE_ZOOM_MODE':
            return { ...state, zoomMode: false };
        default:
            return state;
    }
};

const ZoomProvider = ({ children }) => {
    const [state, dispatch] = useReducer(zoomReducer, { scale: 1, activeZoom: null, zoomMode: false });

    return (
        <ZoomContext.Provider value={{ state, dispatch }}>
            {children}
        </ZoomContext.Provider>
    );
};

export { ZoomContext, ZoomProvider };
