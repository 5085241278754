import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { LuPlus } from "react-icons/lu";
import PencilFileicon from "../../images/pencil_file.png";

const OpenFile = () => {
    const [fileData, setFileData] = useState(null);
    const navigate = useNavigate();

    const handleFileUpload = (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();

        reader.onload = (e) => {
            try {
                const json = JSON.parse(e.target.result);
                setFileData(json);
                navigate('/workspace', { state: json });
            } catch (err) {
                console.error('Error parsing JSON:', err);
                alert('Invalid JSON file');
            }
        };

        if (file) {
            reader.readAsText(file);
        }
    };

    return (
        <>
            <div className="bg-[#FFF] p-[35px] mt-[70px] min-h-[250px] mb-[30px] border border-[#D7D6D6] rounded-[15px] shadow-[0_0px_50px_10px_rgb(0 0 0 / 5%)] w-full lg:max-w-[460px] mx-auto flex flex-col items-center justify-center">
                <img src={PencilFileicon} alt="" />
                <h2 className="text-xl text-[#505050] font-semibold mt-3 leading-tight text-center">
                    Open file from your computer
                </h2>
                <div className="custom-file-type">
                    <input type="file" onChange={handleFileUpload} />
                    <label>
                        <LuPlus />
                        Upload file
                    </label>
                </div>
            </div>
        </>
    );
}

export default OpenFile;
