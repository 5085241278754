import React from 'react'

const RotateIcon = () => {
  return (
    <div className='flex items-center text-sm p-3'>
      <p className='mr-4 font-medium'>Rotate Tool</p>
    </div>
  )
}

export default RotateIcon