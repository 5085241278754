import React, { useState, useEffect } from 'react';
import { GrClose } from "react-icons/gr";
import { FaCheck } from "react-icons/fa";

const TextIcon = () => {
  const [fontFamilies, setFontFamilies] = useState([]);

  useEffect(() => {
    fetch('https://www.googleapis.com/webfonts/v1/webfonts?key=AIzaSyDtw-BqXL0T-3XbryvHMJj1cfnu_EDXMAY')
      .then(response => response.json())
      .then(data => {
        const families = data.items.map(item => item.family);
        setFontFamilies(families);
      })
      .catch(error => console.error('Error fetching fonts:', error));
  }, []);

  return (
    <div className='flex items-center text-sm p-3'>
      <p className='mr-4 font-medium'>Text Tool</p>
      <select className='border border-gray-300 rounded-md p-1 outline-none'>
        <option disabled selected>Font Family</option>
        {fontFamilies.map((fontFamily, index) => (
          <option key={index} value={fontFamily}>{fontFamily}</option>
        ))}
      </select>
      <select className='border border-gray-300 rounded-md p-1 mx-4 outline-none'>
        <option>Light</option>
        <option>Normal</option>
        <option>Bold</option>
        <option>Italic</option>
        <option>Bold Italic</option>
      </select>
      <input
        className='border border-gray-300 rounded-md p-1 w-[60px] text-center outline-none'
        type='text'
        defaultValue='10 px'
      />
      <div className='flex items-center gap-2 ml-4'>
        <button className='text-red-500 outline-none'>
          <GrClose size={16} />
        </button>
        <button className='text-green-500 outline-none'>
          <FaCheck size={16} />
        </button>
      </div>
    </div>
  );
}

export default TextIcon;
