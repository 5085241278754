import React from 'react'

const ScaleIcon = () => {
  return (
    <div className='flex items-center text-sm p-3'>
      <p className='mr-4 font-medium'>Scale Tool</p>
    </div>
  )
}

export default ScaleIcon