// CreateNew.jsx
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Verticalicon from "../../images/vertical.svg";
import VerticalSelecticon from "../../images/vertical-selected.svg";
import Horizontalicon from "../../images/horizontal.svg";
import HorizontalSelecticon from "../../images/horizontal-selected.svg";
import Mobileicon from "../../images/mobile.svg";
import MobileSelecticon from "../../images/mobile-selected.svg";
import Webicon from "../../images/web.svg";
import WebSelecticon from "../../images/web-selected.svg";
import Printicon from "../../images/print.svg";
import PrintSelecticon from "../../images/print-selected.svg";
import Filmicon from "../../images/video.svg";
import FilmSelecticon from "../../images/video-selected.svg";
import Illustratoricon from "../../images/illustration.svg";
import IllustratorSelecticon from "../../images/illustration-selected.svg";
import { toast } from "react-toastify";

const projectPresets = {
    "A4": { width: 210, height: 297 },
    "A3": { width: 297, height: 420 },
    "A5": { width: 148, height: 210 },
    "Letter": { width: 8.5 * 25.4, height: 11 * 25.4 },
    "Legal": { width: 8.5 * 25.4, height: 14 * 25.4 },
};

const projectTypes = [
    { id: "mobile", icon: Mobileicon, selectedIcon: MobileSelecticon, label: "Mobile", className: "mx-auto max-w-[50px] min-w-[50px] w-full" },
    { id: "web", icon: Webicon, selectedIcon: WebSelecticon, label: "Web", className: "mx-auto max-w-[80px] min-w-[80px] w-full" },
    { id: "print", icon: Printicon, selectedIcon: PrintSelecticon, label: "Print", className: "mx-auto max-w-[80px] min-w-[80px] w-full" },
    { id: "film", icon: Filmicon, selectedIcon: FilmSelecticon, label: "Film/Video", className: "mx-auto max-w-[80px] min-w-[80px] w-full", disabled: true },
    { id: "illustration", icon: Illustratoricon, selectedIcon: IllustratorSelecticon, label: "Illustration", className: "mx-auto max-w-[80px] min-w-[80px] w-full", disabled: true },
];

const orientations = [
    { id: "vertical", icon: Verticalicon, selectedIcon: VerticalSelecticon },
    { id: "horizontal", icon: Horizontalicon, selectedIcon: HorizontalSelecticon },
];

const CreateNew = () => {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        projectName: '',
        projectType: 'mobile',
        units: 'Millimeters',
        width: projectPresets['A4'].width,
        height: projectPresets['A4'].height,
        artboardCount: '',
        colorMode: 'RGB',
        orientation: 'vertical',
    });

    const handleTypeClick = (type) => {
        if (!type.disabled) {
            setFormData({ ...formData, projectType: type.id });
        }
    };

    const handleOrientationClick = (orientation) => {
        setFormData({ ...formData, orientation });
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        if (name === "preset") {
            const { width, height } = projectPresets[value];
            setFormData({ ...formData, width: convertToUnit(width, formData.units), height: convertToUnit(height, formData.units), [name]: value });
        } else if (name === "units") {
            setFormData({ ...formData, width: convertToUnit(formData.width, value), height: convertToUnit(formData.height, value), units: value });
        } else {
            setFormData({ ...formData, [name]: value });
        }
    };

    const convertToPixels = (value, unit) => {
        switch (unit) {
            case "Millimeters":
                return value * 3.77953;
            case "Centimeters":
                return value * 37.7953;
            case "Inches":
                return value * 96;
            case "Percent":
                return (value * window.innerWidth) / 100;
            default:
                return value;
        }
    };

    const convertToUnit = (value, unit) => {
        switch (unit) {
            case "Millimeters":
                return value / 3.77953;
            case "Centimeters":
                return value / 37.7953;
            case "Inches":
                return value / 96;
            case "Percent":
                return (value / window.innerWidth) * 100;
            default:
                return value;
        }
    };

    const handleSubmit = () => {
        if (formData.height === "" || formData.width === "") {
            toast.error('Preset fields cannot be blank.');
        } else {
            navigate('/workspace', { state: { width: convertToPixels(formData.width, formData.units), height: convertToPixels(formData.height, formData.units) } });
        }
    };

    return (
        <div className="bg-[#F9F9F9] p-[35px] border border-[#D7D6D6] rounded-[15px] shadow-[0_0px_50px_10px_rgb(0 0 0 / 5%)] w-full xl:max-w-[800px] mx-auto">
            <div className="mb-[25px]">
                <h2 className="font-semibold text-[#505050] text-xl mb-2.5 leading-tight">New Project</h2>
                <p className="text-sm font-medium text-[#505050] leading-tight">Create a new blank artboard</p>
            </div>
            <div className="mb-5">
                <label className="block uppercase text-sm mb-2.5 text-[#8a8a8a] font-medium leading-tight">PROJECT NAME</label>
                <input
                    className="block leading-tight xl:w-10/12 w-full border-b-[1px] border-[#d0d0d0] bg-transparent text-sm text-[#505050] outline-0 h-10 font-medium indent-2.5"
                    type="text"
                    name="projectName"
                    placeholder="Untitled"
                    value={formData.projectName}
                    onChange={handleInputChange}
                />
            </div>
            <div className="mb-5">
                <label className="block leading-tight uppercase text-sm mb-[15px] text-[#8a8a8a] font-medium">PROJECT TYPE</label>
                <div className="flex flex-row gap-[30px] items-end">
                    {projectTypes.map((type) => (
                        <div
                            key={type.id}
                            className={`text-center w-[98px] flex items-center gap-5 flex-col cursor-pointer ${formData.projectType === type.id ? 'active' : ''} ${type.disabled ? 'cursor-not-allowed' : ''}`}
                            onClick={() => handleTypeClick(type)}
                        >
                            <img
                                src={formData.projectType === type.id ? type.selectedIcon : type.icon}
                                alt={type.label}
                                className={type.className}
                            />
                            <p className={`text-sm font-medium leading-tight ${formData.projectType === type.id ? 'text-[#ff9354]' : 'hover:text-[#ff9354]'} ${type.disabled ? 'text-[#d0d0d0] hover:text-[#d0d0d0]' : ''}`}>{type.label}</p>
                        </div>
                    ))}
                </div>
            </div>
            <div className="mb-5">
                <label className="block leading-tight uppercase text-sm mb-2.5 text-[#8a8a8a] font-medium">Document Preset</label>
                <select
                    name="preset"
                    className="block leading-tight xl:w-10/12 w-full border-b-[1px] border-[#d0d0d0] bg-transparent text-sm text-[#505050] outline-0 h-10 font-medium indent-2.5"
                    value={formData.preset}
                    onChange={handleInputChange}
                >
                    <option value="A4">A4 (210 x 297 mm)</option>
                    <option value="A3">A3 (297 x 420 mm)</option>
                    <option value="A5">A5 (148 x 210 mm)</option>
                    <option value="Letter">Letter (8.5 x 11 inches)</option>
                    <option value="Legal">Legal (8.5 x 14 inches)</option>
                </select>
            </div>
            <div className="mb-5">
                <label className="block leading-tight uppercase text-sm mb-2.5 text-[#8a8a8a] font-medium">Preset Details</label>
                <div className="flex items-end">
                    <div className="flex items-center gap-4">
                        <div>
                            <input
                                className="block leading-tight border-b-[1px] max-w-[90px] border-[#d0d0d0] bg-transparent text-sm text-[#505050] outline-0 h-10 font-medium indent-2.5"
                                type="number"
                                name="width"
                                value={formData.width}
                                onChange={handleInputChange}
                            />
                            <label className="block leading-tight uppercase text-center text-sm mt-2.5 text-[#8a8a8a] font-medium">WIDTH</label>
                        </div>
                        <div>
                            <p className="text-[15px] leading-tight font-medium text-[#8a8a8a] mt-2">X</p>
                        </div>
                        <div>
                            <input
                                className="block leading-tight border-b-[1px] max-w-[90px] border-[#d0d0d0] bg-transparent text-sm text-[#505050] outline-0 h-10 font-medium indent-2.5"
                                type="number"
                                name="height"
                                value={formData.height}
                                onChange={handleInputChange}
                            />
                            <label className="block leading-tight uppercase text-center text-sm mt-2.5 text-[#8a8a8a] font-medium">HEIGHT</label>
                        </div>
                    </div>
                    <div className="ml-9">
                        <select
                            name="units"
                            className="min-w-[130px] leading-tight block border-b-[1px] border-[#d0d0d0] bg-transparent text-sm text-[#505050] outline-0 h-10 font-medium indent-2.5"
                            value={formData.units}
                            onChange={handleInputChange}
                        >
                            <option value="Millimeters">Millimeters</option>
                            <option value="Pixels">Pixels</option>
                            <option value="Percent">Percent</option>
                            <option value="Centimeters">Centimeters</option>
                            <option value="Inches">Inches</option>
                        </select>
                        <label className="block leading-tight uppercase text-center text-sm mt-2.5 text-[#8a8a8a] font-medium">Units</label>
                    </div>
                    <div className="ml-10">
                        <div className="flex items-end justify-center gap-2.5">
                            {orientations.map((orientation) => (
                                <div key={orientation.id}>
                                    <img
                                        src={formData.orientation === orientation.id ? orientation.selectedIcon : orientation.icon}
                                        alt={orientation.id}
                                        className="mx-auto max-w-[30px] min-w-[30px] w-full cursor-pointer"
                                        onClick={() => handleOrientationClick(orientation.id)}
                                    />
                                </div>
                            ))}
                        </div>
                        <label className="block leading-tight uppercase text-center text-sm mt-2.5 text-[#8a8a8a] font-medium">Orientation</label>
                    </div>
                </div>
                <div className="flex items-end mt-5">
                    <div className="flex items-center gap-4">
                        <div>
                            <input
                                className="block leading-tight border-b-[1px] max-w-[90px] border-[#d0d0d0] bg-transparent text-sm text-[#505050] outline-0 h-10 font-medium indent-2.5"
                                type="number"
                                name="artboardCount"
                                value={formData.artboardCount}
                                onChange={handleInputChange}
                            />
                            <label className="block leading-tight uppercase text-center text-sm mt-2.5 text-[#8a8a8a] font-medium">ARTBOARD</label>
                        </div>
                    </div>
                    <div className="ml-10">
                        <select
                            name="colorMode"
                            className="min-w-[110px] leading-tight block border-b-[1px] border-[#d0d0d0] bg-transparent text-sm text-[#505050] outline-0 h-10 font-medium indent-2.5"
                            value={formData.colorMode}
                            onChange={handleInputChange}
                        >
                            <option value="RGB">RGB</option>
                            <option value="CMYK">CMYK</option>
                        </select>
                        <label className="block leading-tight uppercase text-center text-sm mt-2.5 text-[#8a8a8a] font-medium">COLOR MODE</label>
                    </div>
                </div>
            </div>
            <div className="mt-7 text-end">
                <button className="bg-[#FF9354] text-white rounded-[5px] py-2.5 px-[26px] font-medium" onClick={handleSubmit}>Create</button>
            </div>
        </div>
    );
};

export default CreateNew;
