import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { FaChevronRight } from "react-icons/fa6";

const Dropdown = ({ label, menuItems }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const closeDropdown = (e) => {
    if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", closeDropdown);
    return () => {
      document.removeEventListener("mousedown", closeDropdown);
    };
  }, []);

  return (
    <div className="relative inline-block text-left" ref={dropdownRef}>
      <div>
        <button
          type="button"
          className="inline-flex w-full justify-center gap-x-1.5 bg-transparent px-3 py-2 text-sm font-medium text-black"
          id="menu-button"
          aria-expanded={isOpen}
          aria-haspopup="true"
          onClick={toggleDropdown}
        >
          {label}
        </button>
      </div>

      {isOpen && (
        <div
          className="absolute left-0 z-[999] mt-2 w-48 tracking-wide origin-top-left divide-y divide-[#dddddd] bg-white shadow-[0_0_50px_10px_rgba(0,0,0,0.05)] border-[1px] border-[#ECECEC]"
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="menu-button"
        >
          {menuItems.map((group, groupIndex) => (
            <div key={groupIndex} className="py-1" role="none">
              {group.items.map((item, itemIndex) => (
                <div key={itemIndex} className="relative group">
                  <Link
                    to={item.to}
                    className="text-black font-medium flex tracking-wide justify-between items-center block px-6 py-2.5 text-xs"
                    role="menuitem"
                    id={`menu-item-${groupIndex}-${itemIndex}`}
                  >
                    {item.label}
                    {item.subMenuItems && <FaChevronRight />}
                  </Link>
                  {item.subMenuItems && (
                    <div className="absolute left-full top-0 mt-[-1px] hidden group-hover:block">
                      <div
                        className="z-20 w-48 origin-top-left divide-y divide-[#dddddd] bg-white shadow-[0_0_50px_10px_rgba(0,0,0,0.05)] border-[1px] border-[#ECECEC]"
                        role="menu"
                        aria-orientation="vertical"
                      >
                        {item.subMenuItems.map((subItem, subItemIndex) => (
                          <Link
                            key={subItemIndex}
                            to={subItem.to}
                            className="text-black tracking-wide font-medium block px-6 py-2.5 text-xs"
                            role="menuitem"
                            id={`sub-menu-item-${groupIndex}-${itemIndex}-${subItemIndex}`}
                          >
                            {subItem.label}
                          </Link>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              ))}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Dropdown;
